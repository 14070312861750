import { useState } from 'react';
import { AmazonButton } from 'components/common/AmazonButton';
import { backendHost, backendSsoUrl } from 'utils/constants';
import useDefaultParamsCountry from 'utils/useDefaultParamsCountry';
import { ssoLoginTracking } from 'Marketing/loginTracking';

export const LoginWithAmazon = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const country = useDefaultParamsCountry();

  const handleClickAmazon = () => {
    setIsButtonDisabled(true);
    ssoLoginTracking(country);
    global.location.assign(`${backendHost}${backendSsoUrl}`);
  };

  return (
    <AmazonButton
      disabled={isButtonDisabled}
      style={{ width: '100%' }}
      onClick={handleClickAmazon}
      text="Login with Amazon"
    />
  );
};
