import { uniqueNumbers, uniqueStrings01, uniqueStrings02, uniqueStrings03 } from './codeConstants';

const words = [uniqueStrings01, uniqueNumbers, uniqueStrings02, uniqueStrings03];

/**
 * Logic to be swapped with the scripts get from VQClientCreditsReferral
 */

export const getCode = (companyId: string | number | null | undefined) => {
  if (typeof companyId !== 'number') return null;
  const id =
    String(companyId).length < 6
      ? String(companyId).padStart(6, '0')
      : String(companyId).padStart(8, '0');
  const code: string[] = ['veeqo'];

  for (let i = 0; i < id.length; i += 2) {
    const val = +id.slice(i, i + 2);
    const codeWord = words[i / 2][val];
    code.push(codeWord.toLowerCase());
  }

  return code.join('-');
};

export const getCompanyId = (referralCode: string | number | undefined | null) => {
  if (referralCode === undefined || referralCode === null) return null;
  const strings = String(referralCode).toLowerCase().split('-');
  const codeArray = strings.slice(1);

  if (codeArray.length === 0) return null;

  const codeStrs: string[] = [];
  for (let i = 0; i < codeArray.length; i += 1) {
    const wordList = words[i];
    const codeI = wordList?.findIndex?.((val) => val.toLowerCase() === codeArray[i]);
    if (codeI >= 0) {
      codeStrs.push(String(codeI).padStart(2, '0'));
    } else {
      return null;
    }
  }
  return +codeStrs.join('');
};
