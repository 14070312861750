/**
 * Clean argument from a list of unwanted properties:
 */
export const sanitiser = (properties: any) => {
  const excludedValues = /(p.*w(o)?(r)?d)|secret|token|credential(s)?/i;

  if (properties === null) return properties; // e.g sanitiser(null) -> null
  if (typeof properties !== 'object') return properties; // e.g sanitiser(undefined) -> undefined, sanitiser('hi') -> 'hi'
  if (properties.length) return properties.map((prop: any) => sanitiser(prop));
  if (Array.isArray(properties)) return properties; // e.g sanitiser([]) -> []

  const newProperties: { [key: string]: any } = {}; // e.g sanitiser({}) -> {}
  Object.keys(properties)
    .filter((key: string) => !excludedValues.test(key))
    .forEach((key: string) => {
      newProperties[key] = sanitiser(properties[key]);
    });
  return newProperties;
};
