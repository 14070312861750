import { identifySegmentEvent, trackSegmentEvent } from './utils/helpers';
import { Action, Event, Location, Target } from './types';

/**
 * Track login initiated via Amazon SSO
 */
export const ssoLoginTracking = (country: string | undefined) => {
  identifySegmentEvent(
    {
      country,
      password: '', // keep empty
    },
    { salesforce: false },
  );
  trackSegmentEvent(
    Event.BUTTON_CLICKED,
    {
      location: Location.LOGIN_HOMEPAGE,
      action: Action.LOGIN_WITH_AMAZON,
      target: Target.AMAZON_SSO_LOGIN,
      country,
    },
    { salesforce: false },
  );
};

/**
 * Track login initiated via email
 */
export const loginTracking = (email: string) => {
  identifySegmentEvent(
    {
      email,
      password: '', // keep empty
    },
    { salesforce: false },
  );
  trackSegmentEvent(
    Event.BUTTON_CLICKED,
    {
      location: Location.LOGIN_HOMEPAGE,
      action: Action.LOGIN_WITH_EMAIL,
      target: Target.VEEQO_APP,
      email,
    },
    { salesforce: false },
  );
};
