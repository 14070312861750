/* eslint-disable no-console */
import { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';

const MAX_RETRIES = 5; // Maximum number of retries
const RETRY_DELAY = 250; // Delay between retries (in ms)

const retry = (fn: () => boolean, retryCount = 0) => {
  try {
    const result = fn();

    if (result === false && retryCount < MAX_RETRIES) {
      setTimeout(() => {
        retry(fn, retryCount + 1);
      }, RETRY_DELAY * retryCount);
    } else {
      if (result === true) console.debug('AA loaded');
      if (retryCount >= MAX_RETRIES) {
        console.debug('AA failed to load');
        Sentry.captureMessage(`AA Not loaded after ${MAX_RETRIES} attempts`);
      }
    }
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * Tries to call a passed function upto three times until it returns true.
 *
 * **Example**: Adobe Analytics is brought in late, but we need to track a page view event. The fn
 * returns false when it hasn't successfully sent the event.
 */
export const useRetryCallOnce = (fn: () => boolean) => {
  const hasCalled = useRef(false); // Workaround for React.StrictMode.

  useEffect(() => {
    if (hasCalled.current) return;
    hasCalled.current = true;

    retry(fn);
  }, [fn]);
};
