import styled from 'styled-components';
import { theme } from '@veeqo/ui';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 420px;
  margin-top: ${theme.sizes[4]};
`;

export const FormContainer = styled.div`
  width: 100%;
`;

export const Link = styled.a`
  width: 100%;
  text-align: center;
`;

export const Form = styled.form`
  button {
    width: 100%;
  }
  .stack--full-width {
    width: 100%;
  }
`;
