import React from 'react';
import ReactDOM from 'react-dom/client';
import { ContextProvider } from 'context/Context';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { isDirectBundlePreview } from './utils/helpers';

import reportWebVitals from './utils/reportWebVitals';
import App from './App';
import './index.css';

// setup sentry on load
import './setupSentry';

const rootElement = document.getElementById('root') as HTMLElement;
if (
  rootElement &&
  (rootElement.classList.contains('react-signup-root') ||
    rootElement.classList.contains('react-new_signup-root'))
) {
  const Router = isDirectBundlePreview() ? HashRouter : BrowserRouter;
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Router basename="/">
        <ContextProvider>
          <App />
        </ContextProvider>
      </Router>
    </React.StrictMode>,
  );
  // eslint-disable-next-line no-console
  if (isDirectBundlePreview()) reportWebVitals(console.log);
}
