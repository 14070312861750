import styled from 'styled-components';
import { Text, Button, theme } from '@veeqo/ui';
import Footer from 'components/layout/Footer';
import success from 'assets/success.png';
import line from 'assets/line.svg';
import cover from 'assets/video-cover.webp';

const Wrapper = styled.div`
  overflow: hidden;
`;

const HeaderContainer = styled.header`
  background-color: #437dcd;
  padding: ${theme.sizes[12]} ${theme.sizes[9]};
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  span {
    color: white;
  }

  .header__text {
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: ${theme.sizes[3]};
    text-align: center;
    margin-top: ${theme.sizes[6]};
  }
`;

const IconContainer = styled.div`
  background-color: #f4cf4b;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  z-index: 2;

  img {
    width: 80px;
  }
`;

const LineContainer = styled.div`
  position: absolute;
  bottom: -122px;
  left: 0;
  right: 0;

  img {
    z-index: 1;
    width: 400px;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(34deg);
    opacity: 0.1;
  }

  @media (min-width: 470px) {
    left: 100px;
    img {
      width: 450px;
    }
  }

  @media (min-width: 600px) {
    left: 113px;
    img {
      width: 500px;
    }
  }
`;

const VideoContainer = styled.section`
  background-color: white;
  padding: ${theme.sizes[12]} ${theme.sizes[9]};
  text-align: center;

  .video__text {
    display: flex;
    flex-direction: column;
    gap: ${theme.sizes[3]};
    text-align: center;
    margin-bottom: ${theme.sizes[8]};
  }

  video {
    width: 100%;
    border-radius: 5px;
  }
`;

const QuestionsContainer = styled.section`
  background-color: #437dcd;
  padding: ${theme.sizes[10]} ${theme.sizes[9]};
  margin-bottom: -40px;
  display: flex;
  flex-direction: column;
  align-items: start;

  span {
    color: white;
  }

  .questions__text {
    display: flex;
    flex-direction: column;
    text-align: start;
    gap: ${theme.sizes[3]};
  }

  .questions__button-group {
    display: flex;
    gap: ${theme.sizes[3]};
    margin-top: ${theme.sizes[6]};
  }

  @media (min-width: 500px) {
    align-items: center;
    padding: ${theme.sizes[12]} ${theme.sizes[16]};
    .questions__text {
      text-align: center;
    }
  }
`;

const SuccessIcon = () => (
  <IconContainer>
    <img src={success} alt="success" />
  </IconContainer>
);

const CtaButton = styled(Button)`
  background-color: #f6c085;
  border: #f6c085;

  span {
    color: black;
  }

  &:hover {
    background-color: #f6c085;
  }

  &:active {
    background-color: #f6c085;
  }

  &:focus-visible {
    background-color: #f6c085;
  }
`;

const Header = () => (
  <HeaderContainer>
    <SuccessIcon />
    <div className="header__text">
      <Text variant="headingXL">You're almost there</Text>
      <Text variant="body">
        We've emailed you with a quick setup link. Check your email on desktop to continue.
      </Text>
    </div>
    <LineContainer>
      <img src={line} alt="line" />
    </LineContainer>
  </HeaderContainer>
);

const Video = () => (
  <VideoContainer>
    <div className="video__text">
      <Text variant="headingMedium">See Veeqo in action!</Text>
      {/* <Text variant='subheadingMedium'>It's all for free!</Text> */}
    </div>
    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
    <video
      src="https://static.veeqo.com/static_content/media/signup_mobile_waitlist/veeqo-product-explainer.mp4"
      poster={cover}
      controls
    />
  </VideoContainer>
);

const Questions = () => {
  const handleClickDemo = () => {
    window.location.href = 'https://www.veeqo.com/book-a-demo';
  };

  return (
    <QuestionsContainer>
      <div className="questions__text">
        <Text variant="headingXL">Got questions?</Text>
        <Text variant="body">
          You can book a hands-on demo to learn how easy it is to use Veeqo to grow your online
          sales.
        </Text>
      </div>
      <div className="questions__button-group">
        <CtaButton variant="primary" size="sm" onClick={handleClickDemo}>
          Book a demo
        </CtaButton>
      </div>
    </QuestionsContainer>
  );
};

const MobileWaitingListSuccess = () => (
  <Wrapper>
    <Header />
    <Video />
    <Questions />
    <Footer />
  </Wrapper>
);

export default MobileWaitingListSuccess;
