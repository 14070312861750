import styled from 'styled-components';
import { motion } from 'framer-motion';

const InputGroup = styled(motion.div)`
  width: 100%;

  input {
    width: 100%;
  }
`;

export default InputGroup;
