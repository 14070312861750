import { createRef } from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import AnnounceKit from 'announcekit-react';
import { theme } from '@veeqo/ui';
import Aside, { Colors } from '../../layout/Aside';

const AnnounceKitWrapper = styled(motion.div)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 ${theme.sizes[8]};
  z-index: 1;
`;

const AnnounceKitContainer = styled.div`
  flex-basis: 80%;
`;

const LoginAside = () => {
  const ref = createRef<any>();

  return (
    <Aside backgroundColor={Colors.BLUE}>
      <AnnounceKitWrapper
        initial={{ x: 200, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ delay: 0.2 }}
      >
        <AnnounceKitContainer>
          <AnnounceKit
            ref={ref}
            embedWidget
            widget="https://changelog.veeqo.com/widgets/v2/4aZAWI"
          />
        </AnnounceKitContainer>
      </AnnounceKitWrapper>
    </Aside>
  );
};

export default LoginAside;
