import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { theme, Button, Stack, Select, TextField, Checkbox } from '@veeqo/ui';
import InputGroup from 'components/common/InputGroup';
import countries from 'utils/countries.json';
import formValidations from 'utils/validations';
import { useAppContext } from 'context/Context';
import { ROWwaitingListTracking } from 'Marketing/ROWwaitingListTracking';
import { MARKETING_OPT_IN_COPY } from '../../constants';

interface ROWWaitingListFormProps {
  setSuccess: (email: string) => void;
}

export type ROWFormData = {
  email: string;
  country: string;
  marketingConsent: boolean;
};

const FormContainer = styled.div`
  margin-top: ${theme.sizes[11]};
`;

const Form = styled.form`
  button {
    width: 100%;
  }

  .stack--full-width {
    width: 100%;
  }
`;

const ROWWaitingListForm = ({ setSuccess }: ROWWaitingListFormProps) => {
  const navigate = useNavigate();
  const {
    actions,
    state: { signupData },
  } = useAppContext();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ROWFormData>({
    defaultValues: {
      email: signupData.email,
      country: '',
      marketingConsent: false,
    },
  });

  const [showEmailField, setShowEmailField] = useState(false);

  const onSubmit = handleSubmit((data) => {
    ROWwaitingListTracking({
      ...data,
      firstName: signupData.firstName,
      lastName: signupData.lastName,
    });
    setSuccess(data.email);
  });

  const onCountryChange = (value: string) => {
    if (value === 'US' || value === 'GB') {
      actions.setSignupData({ country: value });
      if (signupData.email) {
        navigate('/signup/complete');
      } else {
        navigate('/signup');
      }
    } else {
      setShowEmailField(true);
    }
  };

  const handleCountryError = () => {
    if (errors.country) {
      return 'Please select your country';
    }
  };

  return (
    <FormContainer>
      <Form onSubmit={onSubmit}>
        <Stack spacing={6}>
          <InputGroup>
            <Controller
              name="country"
              control={control}
              rules={formValidations.country}
              render={({ field: { onChange, value } }) => (
                <Select
                  label="Country"
                  value={value}
                  options={countries}
                  onChange={(e) => {
                    onCountryChange(e);
                    onChange(e);
                  }}
                  error={handleCountryError()}
                />
              )}
            />
          </InputGroup>
          {showEmailField && (
            <InputGroup initial={{ height: 0 }} animate={{ height: 'auto' }}>
              <Controller
                name="email"
                control={control}
                rules={formValidations.email}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    onChange={onChange}
                    value={value}
                    aria-label="email"
                    type="email"
                    label="Email"
                    error={errors.email && errors.email.message}
                    placeholder="youremail@mail.com"
                    className="fs-exclude"
                  />
                )}
              />
            </InputGroup>
          )}
          <Controller
            name="marketingConsent"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Checkbox label={MARKETING_OPT_IN_COPY} onChange={onChange} checked={value} />
            )}
          />
          <Button type="submit" variant="primary">
            Let me know
          </Button>
        </Stack>
      </Form>
    </FormContainer>
  );
};

export default ROWWaitingListForm;
