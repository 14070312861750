export const amazonGetProfileUrl = 'https://api.amazon.com/user/profile';
// must be blank for prod build, for dev use `REACT_APP_BACKEND_HOST=http://localhost:3000`
export const backendHost = process.env.REACT_APP_BACKEND_HOST || '';
export const backendSignupUrl = '/amazon_lwa/complete';
export const backendLoginUrl = '/sessions';
export const backendLoginVerifyUrl = '/sessions/verify';
export const backendSsoUrl = '/amazon_lwa';
// Veeqo (test) 'amzn1.sp.solution.cd1954fc-1a0b-4a9a-9cc5-34bfe315f866'
export const spApiApplicationId =
  process.env.REACT_APP_SP_API_APPLICATION_ID ||
  'amzn1.sellerapps.app.afd0c369-bc71-4b4e-8f30-1cae134c332e';
// veeqo test 'amzn1.application-oa2-client.7ff8dd6fcb3d40f8823625debd3ac5c3';
// vlad.veeqo.amazon.dev 'amzn1.application-oa2-client.0b57251fa9e0485ca25ccbdee88db80f';
export const lwaClientId =
  process.env.REACT_APP_LWA_CLIENT_ID ||
  'amzn1.application-oa2-client.06c4a0de9f2149c1b0b7a951a66c7fca';

export const lwaOptions = {
  pkce: true,
  interactive: 'auto',
  popup: true,
  response_type: 'code',
  scope: 'profile postal_code',
  scope_data: {
    profile: { essential: true },
    postal_code: { essential: true },
  },
};
// see `./types.ts`
export const signupDataKeysFromQuery = [
  // from state after SSO
  'email',
  'login',
  'postCode',
  'amazonOauthUid',
  'amazonOauthAccessToken',
  // from sellersentral
  'mwsAuthToken',
  'spapiOauthCode',
  'sellingPartnerId',
];
export const apiHeaders: HeadersInit = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
};
// location autocomplete
// export const awsLocationIndexName = 'explore.place';
export const awsLocationIndexName = 'signup_autocomplete';
export const awsRegion = process.env.REACT_APP_AWS_REGION || 'us-east-1';
// production policy restricted to https://*.veeqo.com/* and https://*.veeqo.amazon.dev/*
export const awsIdentityPoolId =
  process.env.REACT_APP_AWS_IDENTITY_POOL_ID ||
  'us-east-1:0a73201f-ff76-46f8-8b51-0ffba1f1ddb2';

// adding this to be double sure we are in production app - due to manual deployment
export const isRemoteEnvironment =
  /(.*\.)?veeqo.com/.test(window.location.hostname) &&
  window.location.hostname !== 'local.veeqo.com';

export const MONTHLY_ORDER_DIAL_UP_PERCENTAGE = 100;
