import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from 'components/layout/Layout';
import { useWindowSize } from 'utils/useWindowSize';
import ROWWaitingList from 'components/Signup/ROWWaitingList';
import { useRetryCallOnce } from 'utils/adobeAnalytics/useRetryCallOnce';
import { trackInitialPageView } from 'utils/adobeAnalytics/beforeAuth/trackInitialPageView';
import Homepage from './Homepage';
import CompanyDetails from './CompanyDetails';
import MobileWaitingList from '../Mobile/MobileWaitingList';
import HomepageAside from './HomepageAside';

const SignupHomepage = () => {
  // On initial page view send event to AA.
  useRetryCallOnce(trackInitialPageView);

  return (
    <Layout showLogin isEvenSidebar asideContent={<HomepageAside />}>
      <Homepage />
    </Layout>
  );
};

const CompanyDetailsWithLayout = () => (
  <Layout showLogo>
    <CompanyDetails />
  </Layout>
);

const ROWWaitingListWithLayout = () => {
  // On initial page view send event to AA.
  useRetryCallOnce(trackInitialPageView);

  return (
    <Layout showLogo preventAnimations>
      <ROWWaitingList />
    </Layout>
  );
};

const Signup = () => {
  const { isMobile } = useWindowSize();

  if (isMobile) {
    return <MobileWaitingList />;
  }

  return (
    <Routes>
      <Route index element={<SignupHomepage />} />
      <Route path="complete" element={<CompanyDetailsWithLayout />} />
      <Route path="coming-soon" element={<ROWWaitingListWithLayout />} />
    </Routes>
  );
};

export default Signup;
