import { trackSegmentEvent } from './utils/helpers';
import { Action, Event, Location } from './types';

/**
 * Track naviagtion between signup and login screens
 */
export const navigationTracking = (action: Action) => {
  trackSegmentEvent(
    Event.BUTTON_CLICKED,
    {
      location: Location.SIGNUP_NAVBAR,
      target: action === Action.LOGIN ? Location.LOGIN_HOMEPAGE : Location.SIGNUP_HOMEPAGE,
      action,
    },
    { salesforce: false },
  );
};
