import styled from 'styled-components';
import { Text, Tag, Stack, theme } from '@veeqo/ui';

interface TitleGroupProps {
  title: string;
  subtitle: string;
  isMobile?: boolean;
  tag?: string;
}

const GreenTag = styled(Tag)`
  background-color: ${theme.colors.secondary.green.light};
`;

const Container = styled(Stack)<{ isMobile: boolean }>`
  margin-top: ${({ isMobile }) => (isMobile ? theme.sizes[12] : theme.sizes[16])};
  margin-bottom: ${({ isMobile }) => (isMobile ? theme.sizes[6] : theme.sizes[11])};
`;

const TitleGroup = ({ title, subtitle, tag, isMobile = false }: TitleGroupProps) => (
  <Container isMobile={isMobile}>
    {tag && (
      <GreenTag>
        <Text variant="bodySmall">{tag}</Text>
      </GreenTag>
    )}
    <Text variant="headingXL">{title}</Text>
    <Text variant="body">{subtitle}</Text>
  </Container>
);

export default TitleGroup;
