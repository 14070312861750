import React from 'react';
import styled from 'styled-components';
import line from '../../assets/line.svg';

export enum Colors {
  BLUE = 'blue',
  ORANGE = 'orange',
}

interface AsideProps {
  children?: React.ReactNode;
  backgroundColor?: Colors;
}

interface AsideWrapperProps {
  backgroundColor: Colors;
}

const AsideWrapper = styled.aside<AsideWrapperProps>`
  position: relative;
  overflow: hidden;
  background-image: url(${line});
  background-size: cover;
  background-position: center;
  background-color: ${({ backgroundColor }) =>
    backgroundColor === Colors.BLUE ? 'rgba(101, 133, 246, 0.2);' : 'rgb(246, 192, 133, 0.2)'};
`;

const Aside = ({ children, backgroundColor = Colors.ORANGE }: AsideProps) => {
  return <AsideWrapper backgroundColor={backgroundColor}>{children}</AsideWrapper>;
};

export default Aside;
