export const uniqueStrings01 = [
  'AA',
  'AB',
  'AC',
  'AD',
  'AE',
  'AF',
  'AG',
  'AH',
  'AI',
  'AJ',
  'AK',
  'AL',
  'AM',
  'AN',
  'AO',
  'AP',
  'AQ',
  'AR',
  'AS',
  'AT',
  'AU',
  'AV',
  'AW',
  'AX',
  'AY',
  'AZ',
  'BB',
  'BC',
  'BD',
  'BE',
  'BF',
  'BG',
  'BH',
  'BI',
  'BJ',
  'BK',
  'BL',
  'BM',
  'BN',
  'BO',
  'BP',
  'BQ',
  'BR',
  'BS',
  'BT',
  'BU',
  'BV',
  'BW',
  'BX',
  'BY',
  'BZ',
  'CB',
  'CC',
  'CD',
  'CE',
  'CF',
  'CG',
  'CH',
  'CI',
  'CJ',
  'CK',
  'CL',
  'CM',
  'CN',
  'CO',
  'CP',
  'CQ',
  'CR',
  'CS',
  'CT',
  'CU',
  'CV',
  'CW',
  'CX',
  'CY',
  'CZ',
  'DB',
  'DC',
  'DD',
  'DE',
  'DF',
  'DG',
  'DH',
  'DI',
  'DJ',
  'DK',
  'DL',
  'DM',
  'DN',
  'DO',
  'DP',
  'DQ',
  'DR',
  'DS',
  'DT',
  'DU',
  'DV',
  'DW',
  'DX',
  'DY',
];

export const uniqueNumbers = [
  '32',
  '62',
  '30',
  '54',
  '00',
  '58',
  '41',
  '83',
  '13',
  '42',
  '14',
  '76',
  '37',
  '03',
  '21',
  '74',
  '39',
  '91',
  '94',
  '18',
  '63',
  '19',
  '46',
  '60',
  '40',
  '35',
  '70',
  '20',
  '27',
  '05',
  '78',
  '17',
  '92',
  '59',
  '16',
  '87',
  '43',
  '84',
  '93',
  '44',
  '79',
  '02',
  '75',
  '82',
  '07',
  '56',
  '81',
  '24',
  '01',
  '65',
  '99',
  '28',
  '22',
  '12',
  '71',
  '53',
  '96',
  '61',
  '64',
  '88',
  '31',
  '49',
  '66',
  '52',
  '25',
  '50',
  '55',
  '80',
  '45',
  '98',
  '51',
  '47',
  '11',
  '68',
  '48',
  '36',
  '26',
  '06',
  '89',
  '85',
  '04',
  '23',
  '09',
  '33',
  '72',
  '67',
  '08',
  '57',
  '77',
  '90',
  '97',
  '73',
  '95',
  '86',
  '34',
  '69',
  '15',
  '29',
  '38',
  '10',
];

export const uniqueStrings02 = [
  'DZ',
  'EB',
  'EC',
  'ED',
  'EE',
  'EF',
  'EG',
  'EH',
  'EI',
  'EJ',
  'EK',
  'EL',
  'EM',
  'EN',
  'EO',
  'EP',
  'EQ',
  'ER',
  'ES',
  'ET',
  'EU',
  'EV',
  'EW',
  'EX',
  'EY',
  'EZ',
  'FB',
  'FC',
  'FD',
  'FE',
  'FF',
  'FG',
  'FH',
  'FI',
  'FJ',
  'FK',
  'FL',
  'FM',
  'FN',
  'FO',
  'FP',
  'FQ',
  'FR',
  'FS',
  'FT',
  'FU',
  'FV',
  'FW',
  'FX',
  'FY',
  'FZ',
  'GB',
  'GC',
  'GD',
  'GE',
  'GF',
  'GG',
  'GH',
  'GI',
  'GJ',
  'GK',
  'GL',
  'GM',
  'GN',
  'GO',
  'GP',
  'GQ',
  'GR',
  'GS',
  'GT',
  'GU',
  'GV',
  'GW',
  'GX',
  'GY',
  'GZ',
  'HB',
  'HC',
  'HD',
  'HE',
  'HF',
  'HG',
  'HH',
  'HI',
  'HJ',
  'HK',
  'HL',
  'HM',
  'HN',
  'HO',
  'HP',
  'HQ',
  'HR',
  'HS',
  'HT',
  'HU',
  'HV',
  'HW',
  'HX',
  'HY',
];

export const uniqueStrings03 = [
  'HZ',
  'IB',
  'IC',
  'ID',
  'IE',
  'IF',
  'IG',
  'IH',
  'II',
  'IJ',
  'IK',
  'IL',
  'IM',
  'IN',
  'IO',
  'IP',
  'IQ',
  'IR',
  'IS',
  'IT',
  'IU',
  'IV',
  'IW',
  'IX',
  'IY',
  'IZ',
  'JB',
  'JC',
  'JD',
  'JE',
  'JF',
  'JG',
  'JH',
  'JI',
  'JJ',
  'JK',
  'JL',
  'JM',
  'JN',
  'JO',
  'JP',
  'JQ',
  'JR',
  'JS',
  'JT',
  'JU',
  'JV',
  'JW',
  'JX',
  'JY',
  'JZ',
  'KB',
  'KC',
  'KD',
  'KE',
  'KF',
  'KG',
  'KH',
  'KI',
  'KJ',
  'KK',
  'KL',
  'KM',
  'KN',
  'KO',
  'KP',
  'KQ',
  'KR',
  'KS',
  'KT',
  'KU',
  'KV',
  'KW',
  'KX',
  'KY',
  'KZ',
  'LB',
  'LC',
  'LD',
  'LE',
  'LF',
  'LG',
  'LH',
  'LI',
  'LJ',
  'LK',
  'LL',
  'LM',
  'LN',
  'LO',
  'LP',
  'LQ',
  'LR',
  'LS',
  'LT',
  'LU',
  'LV',
  'LW',
  'LX',
  'LY',
];
