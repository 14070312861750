import { addUTMData, identifySegmentEvent, trackSegmentEvent } from './utils/helpers';
import { Action, BasePayload, Event, Location } from './types';

/**
 * Track the first step of signup via the email & login process
 */
export const signupInitiatedTracking = (formData: BasePayload) => {
  const { firstName, lastName, email, country, referralCode, referralCompanyID } = formData;

  const baseData = {
    firstName,
    lastName,
    email,
    country,
    referralCode,
    referralCompanyID,
    get_address: true,
    company: {
      name: email,
    },
  };

  // UTM Data
  const dataWithUTM = addUTMData(baseData);

  // Segment
  identifySegmentEvent(dataWithUTM);
  trackSegmentEvent(Event.BUTTON_CLICKED, {
    location: Location.SIGNUP_HOMEPAGE,
    target: Location.SIGNUP_COMPANY_DETAILS,
    action: Action.CONTINUE_WITH_EMAIL,
  });
};

export const splitFullName = (fullName: string | undefined) => {
  if (!fullName) return { firstName: undefined, lastName: undefined };

  // First word is first name, rest of string is last name

  const splitName = fullName.split(' ');

  return {
    firstName: splitName[0],
    lastName: splitName.slice(1).join(' ') || undefined,
  };
};

/**
 * Track the first step of signup via the Amazon SSO process
 */
export const signupInitiatedSSOTracking = (
  country: string | undefined,
  referralCode?: string,
  referralCompanyID?: number | null,
) => {
  const baseData = { country, referralCompanyID, referralCode };

  // UTM Data
  const dataWithUTM = addUTMData(baseData);

  // Segment
  identifySegmentEvent(dataWithUTM);
  trackSegmentEvent(Event.BUTTON_CLICKED, {
    location: Location.SIGNUP_HOMEPAGE,
    target: Location.AMAZON_SSO_SIGNUP,
    action: Action.CONTINUE_WITH_AMAZON,
    ...baseData,
  });
};
