import { Stack } from '@veeqo/ui';
import TitleGroup from 'components/common/TitleGroup';
import Layout from 'components/layout/Layout';
import { useAppContext } from 'context/Context';
import { useRetryCallOnce } from 'utils/adobeAnalytics/useRetryCallOnce';
import { trackInitialPageView } from 'utils/adobeAnalytics/beforeAuth/trackInitialPageView';
import LoginVerificationForm from './components/LoginVerificationForm';
import LoginAside from './components/LoginAside';
import { Container, FormContainer, Wrapper } from './styled';

export const LoginVerification = () => {
  const {
    state: {
      signupData: { email },
    },
  } = useAppContext();

  // On initial page view send event to AA.
  useRetryCallOnce(trackInitialPageView);

  return (
    <Layout showLogo showSignup asideContent={<LoginAside />}>
      <Wrapper>
        <Container>
          <TitleGroup
            title="Two-Step Verification"
            subtitle={`Enter the verification code sent to ${email} or shown in your authenticator app.`}
          />
          <Stack spacing={8}>
            <FormContainer>
              <LoginVerificationForm />
            </FormContainer>
          </Stack>
        </Container>
      </Wrapper>
    </Layout>
  );
};
