import { PAGE_VIEW_EVENT_NAME } from '../constants';
import { AAWithKnownCompany } from './types';
import { getUTMParameters, reportSatelliteMissing } from '../utils';

/**
 * Called when the user enters their name
 */
export const trackUserSigninSuccess = (userId: string, companyId: string, companyName: string) => {
  if (!_satellite) return reportSatelliteMissing();

  const utmParameters = getUTMParameters();
  const eventData: AAWithKnownCompany = {
    page: {
      url: window.location.href,
      domain: window.location.hostname,
      path: window.location.pathname,
      queryString: window.location.search,
      title: document.title,
      referrer: document.referrer,
      section: 'Sign Up App',
      errorPage: false,
      // Signup specific.
      loginCompleted: true, // User has logged in.
      leadCreated: false,
      accountCreated: false,
    },
    user: {
      membershipType: '',
      id: userId,
      utmParameters,
    },
    account: {
      companyID: companyId,
      companyName,
      membershipType: '',
    },
  };

  _satellite.track(PAGE_VIEW_EVENT_NAME, eventData);
  return true;
};
