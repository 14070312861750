import { useAppContext } from 'context/Context';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from './Login';
import { LoginVerification } from './LoginVerification';

export const LoginRoutes = () => {
  const {
    state: {
      login: { isVerificationRequired },
    },
  } = useAppContext();

  return (
    <Routes>
      <Route index element={<Login />} />
      <Route
        path="verify"
        element={isVerificationRequired ? <LoginVerification /> : <Navigate to="" replace />}
      />
      <Route path="*" element={<Navigate to="" replace />} />
    </Routes>
  );
};
