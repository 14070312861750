import React from 'react';
import { Helmet } from 'react-helmet';
import { sourceMiddleware } from './utils/segmentMiddleware';

const ThirdPartyScripts = () => {
  return (
    <>
      {/* SEGMENT */}
      <Helmet
        script={[
          {
            type: 'text/javascript',
            innerHTML: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="EQU0u07CoBzwdo0o68ESuexvYMPRNQkp";;analytics.SNIPPET_VERSION="4.15.3";
          analytics.addSourceMiddleware(${sourceMiddleware});
          analytics.load("EQU0u07CoBzwdo0o68ESuexvYMPRNQkp");
          analytics.page();
          }}();`,
          },
        ]}
      />
      {/* Adobe Analytics */}
      <Helmet>
        <script
          src={
            process.env.REACT_APP_AA_LAUNCH_SCRIPT_URL ||
            'https://d33ucdt2d9uy4i.cloudfront.net/aa/dev.min.js'
          }
          type="text/javascript"
          id="aa-launch-script"
        />
      </Helmet>
      {/* BING */}
      <Helmet
        script={[
          {
            type: 'text/javascript',
            innerHTML: `(function(w,d,t,r,u)
          {
            var f,n,i;
            w[u]=w[u]||[],f=function()
            {
              var o={ti:" 20114363"};
              o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")
            },
            n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function()
            {
              var s=this.readyState;
              s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)
            },
            i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
          })
          (window,document,"script","//bat.bing.com/bat.js","uetq");`,
          },
        ]}
      />
    </>
  );
};

export default ThirdPartyScripts;
