import { trackSegmentEvent } from './utils/helpers';
import { Action, BasePayload, Event, Location } from './types';

/**
 * Track data succesfully pulled from Amazon SSO
 */
export const ssoDataPullTracking = (ssoData: BasePayload) => {
  const { login, email, country, amazonOauthUid } = ssoData;

  trackSegmentEvent(Event.SUBMIT_SUCCESS, {
    location: Location.SIGNUP_HOMEPAGE,
    origin: Location.AMAZON_SSO_SIGNUP,
    action: Action.AMAZON_SSO_DATA_PULLED,
    ssoData: {
      login,
      email,
      country,
      amazonOauthUid,
    },
  });
};
