import he from 'he';

const formInputSanitiser = (inputString: any) => {
  if (typeof inputString === 'string') {
    return he.escape(inputString);
  }

  return inputString;
};

/**
 * Function sanitising form values to prevent malicious scripts injection
 * @param {object} formInputs
 * @returns {object} sanitised formInputs
 * @example
 * formDataSanitiser({inputField: "<script>alert('XSS attack!');</script>"})
 * // output: {inputField: "&lt;script&gt;alert(&#x27;XSS attack!&#x27;);&lt;/script&gt;"}
 * */
export const formDataSanitiser = (
  formInputs: Record<string, string | boolean | number | undefined | null>,
) => {
  Object.entries(formInputs).forEach(([inputFieldKey, inputFieldValue]) => {
    if (inputFieldKey === 'password') return;
    formInputs[inputFieldKey] = formInputSanitiser(inputFieldValue);
  });
  return formInputs;
};
