import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import rateIllustrationPng from '../../assets/one-click-rates.png';
import rateIllustrationWebp from '../../assets/one-click-rates.webp';
import Aside from '../layout/Aside';

const HomeContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HomeContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  width: 80%;
  flex-basis: 45%;

  img {
    flex-grow: 1;
    width: 100%;
    max-width: 600px;
  }
`;

// 800px show mobile by useWindowSize
const HomepageAside = () => {
  return (
    <Aside>
      <HomeContainer>
        <AnimatePresence initial>
          <HomeContent
            initial={{ x: 200, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.2 }}
          >
            <picture>
              <source type="image/webp" media="(min-width: 800px)" srcSet={rateIllustrationWebp} />
              <source type="image/png" media="(min-width: 800px)" srcSet={rateIllustrationPng} />
              <img src={rateIllustrationPng} alt="one-click rates" width={600} height={460} />
            </picture>
          </HomeContent>
        </AnimatePresence>
      </HomeContainer>
    </Aside>
  );
};

export default HomepageAside;
