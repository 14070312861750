import React, { Dispatch, createContext, useReducer, useContext, useMemo } from 'react';
import {
  setActiveStepAction,
  setCompletedStepAction,
  setSignupDataAction,
  resetSignupDataAction,
  userNeedVerificationAction,
} from './actions';
import { Action, ActionType, State, ContextProviderProps, SignupData, StepNumber } from './types';

const AppContext = createContext({
  state: {} as State,
  dispatch: undefined as unknown as Dispatch<Action>,
});

export const defaultInitialState = {
  signupData: {
    login: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    companyName: '',
    country: 'US',
    addressLine1: '',
    city: '',
    region: '',
    monthlyOrderVolume: '',
    postCode: '',
    phone: '',
    termsAndConditions: false,
    marketingConsent: false,
    timezone: '',
  },
  login: {
    isVerificationRequired: false,
  },
};

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case ActionType.SET_FORM_DATA:
      return { ...state, signupData: { ...state.signupData, ...action.signupData } };
    case ActionType.SET_USER_NEEDS_VERIFICATION:
      return {
        ...state,
        login: { isVerificationRequired: action.isVerificationRequired },
      };
    case ActionType.RESET_SIGNUP_DATA:
      return {
        ...state,
        ...defaultInitialState,
      };
    default:
      throw new Error();
  }
};

export const ContextProvider = ({ children }: ContextProviderProps) => {
  const initialState = defaultInitialState;

  const [state, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  const { state, dispatch } = useContext(AppContext);

  const setActiveStep = (activeStep: StepNumber) => dispatch(setActiveStepAction(activeStep));
  const setSignupData = (signupData: SignupData) => dispatch(setSignupDataAction(signupData));
  const markStepAsCompleted = (step: StepNumber) => dispatch(setCompletedStepAction(step));
  const resetSignupData = () => dispatch(resetSignupDataAction());
  const userNeedVerification = (param: boolean) => dispatch(userNeedVerificationAction(param));

  return {
    actions: {
      // signup
      setActiveStep,
      setSignupData,
      markStepAsCompleted,
      resetSignupData,

      // login
      userNeedVerification,
    },
    state,
  };
};
