export const fetchWithCsrf = (
  url: string,
  options: { headers?: HeadersInit; [key: string]: any } = {},
) => {
  const { headers = {} } = options;
  const meta = document.querySelector('meta[name="csrf-token"]') as any;
  const tokenFromMeta = meta ? meta.content : null;
  const tokenFromLocalStorage = window.localStorage.csrf;
  const token = tokenFromLocalStorage || tokenFromMeta;
  const tokenHeaders: HeadersInit = token ? { 'x-csrf-token': token } : {};
  const fetchOpts = {
    ...options,
    credentials: 'same-origin' as RequestCredentials,
    headers: new Headers({ ...tokenHeaders, ...headers }),
  };
  return fetch(url, fetchOpts);
};
