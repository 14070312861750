import { lwaClientId, lwaOptions, amazonGetProfileUrl } from 'utils/constants';
import { SignupData } from 'context/types';
import { ssoDataPullTracking } from 'Marketing/ssoDataTracking';
import { splitFullName } from 'Marketing/signupInitiatedTracking';

const CodeStatusOk = 'complete';
type CodeResponseOk = {
  status: typeof CodeStatusOk;
  code: string;
  scope: string;
};

type CodeResponseFail = {
  status: Exclude<string, typeof CodeStatusOk>;
};

type CodeResponse = CodeResponseOk | CodeResponseFail;

type TokenResponseOk = {
  success: true;
  expires_in: number;
  access_token: string;
  token_type: string;
};

type TokenResponseFail = {
  success: false;
};

type TokenResponse = TokenResponseOk | TokenResponseFail;

type ProfileResponseOk = {
  error: null;
  email: string;
  user_id?: string;
  postal_code?: string;
  name?: string;
};

type ProfileResponseFail = {
  error: string;
};

type ProfileResponse = ProfileResponseOk | ProfileResponseFail;

type Callback<T> = (arg0: T) => void;

declare namespace amazon {
  namespace Login {
    function authorize(
      options: AccessTokenAuthorizeOptions,
      callback: Callback<CodeResponse>
    ): void;
    function retrieveToken(
      code: string,
      callback: Callback<TokenResponse>
    ): void;
    function retrieveProfile(
      accessToken: string,
      callback: Callback<ProfileResponse>
    ): void;
  }
}

const amazonSignupData = (
  amazonOauthAccessToken: string,
  profileResponse: ProfileResponseOk
) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { email, postal_code, user_id, name } = profileResponse;
  const { firstName, lastName } = splitFullName(name);

  return {
    email,
    firstName,
    lastName,
    postCode: postal_code,
    login: name,
    amazonOauthUid: user_id,
    amazonOauthAccessToken,
  };
};

const amazonGetProfileHref = (accessToken: string) =>
  `${amazonGetProfileUrl}?${new URLSearchParams({
    access_token: accessToken,
  })}`;

export const amazonSso = (
  data: SignupData,
  next: (arg0: SignupData) => void
) => {
  global.amazon.Login.setClientId(lwaClientId);
  global.amazon.Login.authorize(
    lwaOptions as AccessTokenAuthorizeOptions,
    (response) => {
      // npm typing thinks this request returns `TokenResponse` but if `response_type` == 'code' it's wrong, had to redefine types
      const authorizeResponse = response as any as CodeResponse;
      if (authorizeResponse.status === CodeStatusOk) {
        amazon.Login.retrieveToken(
          (authorizeResponse as CodeResponseOk).code,
          async (tokenResponse) => {
            if (tokenResponse.success) {
              // cannot user the proper SDK call on next line, it hides postal_code, had to re-implement :'(
              // amazon.Login.retrieveProfile(tokenResponse.access_token, (profileResponse) => {
              const profile = await fetch(
                amazonGetProfileHref(tokenResponse.access_token)
              );
              const profileResponse: ProfileResponse = await profile.json();
              if (!profileResponse.error) {
                const ssoSignupData = amazonSignupData(
                  tokenResponse.access_token,
                  profileResponse as ProfileResponseOk
                );
                // TODO: redirect right away after sellercentral fixed handling of "no account" with proper redirect to redirect_uri
                // or detect user came from banner in sellercentral and he definetly has an account
                // const SUBREGION_TO_ENDPOINT = { US: 'sellercentral.amazon.com', GB: 'sellercentral.amazon.co.uk' }
                // const state = btoa(JSON.stringify(data)); // passthrough via sellersentral + consider signing redirect mw
                // const url = `https://${SUBREGION_TO_ENDPOINT[country]}/apps/authorize/consent?application_id=${encodeURIComponent(spApiApplicationId)}&version=beta&redirect_uri=${encodeURIComponent(redirectUri)}&state=${encodeURIComponent(state)}`;
                // global.location.href = url;
                //
                // until then we have to go through second step and channel creation first
                // then connect channel starting from the backend
                const updatedData = { ...data, ...ssoSignupData, sso: true };
                ssoDataPullTracking(updatedData);
                next(updatedData);
              }
            }
          }
        );
      }
    }
  );
};
