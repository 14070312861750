import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, theme } from '@veeqo/ui';
import { motion, AnimatePresence } from 'framer-motion';
import TitleGroup from 'components/common/TitleGroup';
import TermsConditions from 'components/Terms/TermsConditions';
import ROWWaitingListForm from './ROWWaitingListForm';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${theme.sizes[18]};
`;

const Content = styled(motion.div)`
  width: 100%;
  max-width: 500px;
`;

const SuccessButton = styled(Button)`
  width: 100%;
`;

const ROWWaitingList = () => {
  const [submittedEmail, setSubmittedEmail] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);

  const copy = {
    form: {
      title: 'Unfortunately Veeqo is not available in your region... yet',
      subtitle:
        'Let us know which region your business is located, and we’ll let you know when Veeqo is available in your region.',
    },
    success: {
      title: "We'll let you know!",
      subtitle: `We’ve added ${submittedEmail} to the waitlist. We’ll let you know when you can sign up and start shipping with Veeqo!`,
    },
  };

  const handleSetSuccess = (email: string) => {
    setSubmittedEmail(email);
    setIsSuccess(true);
  };

  const handleClickSuccess = () => {
    window.location.href = 'https://veeqo.com';
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <AnimatePresence initial>
        <Content initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.4 }}>
          <TitleGroup
            tag="Coming soon"
            title={isSuccess ? copy.success.title : copy.form.title}
            subtitle={isSuccess ? copy.success.subtitle : copy.form.subtitle}
          />
          {isSuccess ? (
            <SuccessButton variant="primary" onClick={handleClickSuccess}>
              Back to Veeqo.com
            </SuccessButton>
          ) : (
            <ROWWaitingListForm setSuccess={handleSetSuccess} />
          )}
          {!isSuccess && <TermsConditions waitingList />}
        </Content>
      </AnimatePresence>
    </Wrapper>
  );
};

export default ROWWaitingList;
