import { MONTHLY_ORDER_DIAL_UP_PERCENTAGE } from './constants';
import { getRandomInt } from './helpers';

export const isMonthlyOrderVolumeVisible = (): boolean => {
  // resets, so all users cannot see if set back to 0
  if (MONTHLY_ORDER_DIAL_UP_PERCENTAGE <= 0) {
    localStorage.setItem('MonthlyOrderVolumeSelectEnabled', 'false');
  }

  const monthlyOrderVolumeEnabled =
    localStorage.getItem('MonthlyOrderVolumeSelectEnabled') === 'true';
  if (monthlyOrderVolumeEnabled) return true;

  const isVisable = getRandomInt(100) % 100 < MONTHLY_ORDER_DIAL_UP_PERCENTAGE;

  if (isVisable) {
    localStorage.setItem('MonthlyOrderVolumeSelectEnabled', 'true');
  }
  return isVisable;
};
