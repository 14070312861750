import React, { useEffect } from 'react';
import styled from 'styled-components';
import { theme } from '@veeqo/ui';
import { motion } from 'framer-motion';
import Header from 'components/common/FormHeader';
import CompanyDetailsForm from './CompanyDetailsForm';

const Wrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${theme.sizes[18]};
`;

const Content = styled.div`
  width: 100%;
  max-width: 500px;
`;

const FormContainer = styled.div`
  margin-top: ${theme.sizes[11]};
`;

const CompanyDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <Content>
        <Header />
        <FormContainer>
          <CompanyDetailsForm />
        </FormContainer>
      </Content>
    </Wrapper>
  );
};

export default CompanyDetails;
