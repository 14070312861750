import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Button, Stack, TextField } from '@veeqo/ui';
import formValidations from 'utils/validations';
import { useAppContext } from 'context/Context';
import InputGroup from 'components/common/InputGroup';
import { signupInitiatedTracking } from 'Marketing/signupInitiatedTracking';
import { trackUserCompletedFirstSignupStage } from 'utils/adobeAnalytics/beforeAuth/trackUserCompletedFirstSignupStage';
import Cookies from 'js-cookie';
import { getCompanyId } from 'utils/creditsReferral';

type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  referralCode: string;
};

const FormContent = styled(Stack)`
  padding: 0 4px;

  button {
    width: 100%;
  }

  .stack--full-width {
    width: 100%;
  }
`;

const UserDetailsForm = () => {
  const navigate = useNavigate();
  const {
    actions: { setSignupData },
    state: {
      signupData: { firstName, lastName, email, password, country },
    },
  } = useAppContext();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: { firstName, lastName, email, password, referralCode: Cookies.get('refer_me') },
  });

  // temp to hide referral code field
  const url = new URL(window.location.href);

  const onSubmit = handleSubmit((formData: FormData) => {
    const referralCompanyID = getCompanyId(formData.referralCode);
    setSignupData({
      ...formData,
      referralCompanyID,
      login: `${formData.firstName} ${formData.lastName}`,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    signupInitiatedTracking({
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      country,
      referralCode: formData.referralCode,
      referralCompanyID,
    });
    trackUserCompletedFirstSignupStage();
    navigate('complete');
  });

  return (
    <form onSubmit={onSubmit}>
      <FormContent spacing={6}>
        <Stack direction="horizontal" className="stack--full-width">
          <InputGroup>
            <Controller
              name="firstName"
              control={control}
              rules={formValidations.firstName}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  aria-label="first name"
                  label="First name"
                  error={errors.firstName && errors.firstName.message}
                  placeholder="First name"
                  className="fs-exclude"
                />
              )}
            />
          </InputGroup>
          <InputGroup>
            <Controller
              name="lastName"
              control={control}
              rules={formValidations.lastName}
              render={({ field: { onChange, value } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  aria-label="last name"
                  label="Last name"
                  error={errors.lastName && errors.lastName.message}
                  placeholder="Last name"
                  className="fs-exclude"
                />
              )}
            />
          </InputGroup>
        </Stack>
        <InputGroup>
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value}
                aria-label="email"
                type="text" // avoid browser validation when starting typing
                label="Email"
                placeholder="youremail@mail.com"
                error={errors.email && errors.email.message}
                className="fs-exclude"
              />
            )}
            rules={formValidations.email}
          />
        </InputGroup>
        <InputGroup>
          <Controller
            name="password"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value}
                aria-label="password"
                type="password"
                label="Password"
                hint="Minimum 8 characters and must contain uppercase and lowercase letters, numbers and symbols (! @ # $ % ^ & * <> -)"
                placeholder="********"
                error={errors.password && errors.password.message}
                className="fs-exclude"
              />
            )}
            rules={formValidations.password}
          />
        </InputGroup>

        <Button type="submit" variant="primary">
          Continue with email
        </Button>
      </FormContent>
    </form>
  );
};

export default UserDetailsForm;
