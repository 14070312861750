import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import { Button, Text, Stack, TextField } from '@veeqo/ui';
import { useForm, Controller } from 'react-hook-form';
import InputGroup from 'components/common/InputGroup';
import { verifyCode } from 'utils/api';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'context/Context';
import { Form } from '../styled';

type FormData = {
  code: string;
};

type Response = {
  error?: boolean;
  isRetryable?: boolean;
  redirectTo?: string;
};

const VerifyCodeForm = () => {
  const { actions } = useAppContext();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const [hasVerificationFailed, setHasVerificationFailed] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const onSubmit = handleSubmit(async (data) => {
    setIsButtonLoading(true);
    const response: Response | undefined = await verifyCode(data);

    if (response?.error && response?.isRetryable) {
      setIsButtonLoading(false);
      setHasVerificationFailed(true);
      return;
    }

    if (response?.error) {
      actions.resetSignupData();
      actions.userNeedVerification(false);
      navigate('..', { replace: true });
    }

    if (response.redirectTo) {
      global.location.href = response.redirectTo;
    } else {
      Sentry.captureMessage('No redirect_to when verifying code');
    }
  });

  return (
    <Form onSubmit={onSubmit}>
      <Stack spacing={6}>
        <InputGroup>
          <Controller
            name="code"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                onChange={onChange}
                value={value}
                aria-label="code"
                type="text"
                label="Verification Code"
                placeholder="Enter code"
                error={errors.code && errors.code.message}
              />
            )}
            rules={{ required: 'Required' }}
          />
        </InputGroup>
        {hasVerificationFailed && (
          <Text variant="errorSmall">The code you entered was incorrect. Please try again</Text>
        )}
        <Button
          type="submit"
          variant="primary"
          loading={isButtonLoading}
          disabled={isButtonLoading}
        >
          Verify
        </Button>
      </Stack>
    </Form>
  );
};

export default VerifyCodeForm;
