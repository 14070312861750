import { Text, Stack } from '@veeqo/ui';

const Header = () => (
  <Stack spacing={3}>
    <Stack direction="horizontal" spacing={4} alignY="center">
      <Text variant="headingMedium">Creating your Veeqo Account</Text>
    </Stack>
    <Text variant="body">
      Let's find out a bit more about your business while we get your account ready!
    </Text>
  </Stack>
);

export default Header;
