import { Action, ActionType, SignupData, StepNumber } from './types';

export const setActiveStepAction = (activeStep: StepNumber): Action => ({
  type: ActionType.SET_ACTIVE_STEP,
  activeStep,
});

export const setSignupDataAction = (signupData: SignupData): Action => ({
  type: ActionType.SET_FORM_DATA,
  signupData,
});

export const setCompletedStepAction = (completedStep: StepNumber): Action => ({
  type: ActionType.SET_COMPLETED_STEP,
  completedStep,
});

export const resetSignupDataAction = (): Action => ({
  type: ActionType.RESET_SIGNUP_DATA,
});

export const userNeedVerificationAction = (isVerificationRequired: boolean): Action => ({
  type: ActionType.SET_USER_NEEDS_VERIFICATION,
  isVerificationRequired,
});
