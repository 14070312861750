import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { Text, Button, Stack, theme } from '@veeqo/ui';
import { motion } from 'framer-motion';
import VeeqoLogo from 'components/common/VeeqoLogo';
import { useWindowSize } from 'utils/useWindowSize';
import { navigationTracking } from 'Marketing/navigationTracking';
import { Action } from 'Marketing/types';

interface NavbarProps {
  logo?: boolean;
  login?: boolean;
  signup?: boolean;
  preventAnimations?: boolean;
}

const NavContainer = styled(Stack)`
  padding: ${theme.sizes[6]} 0;
`;

const Login = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigationTracking(Action.LOGIN);
    navigate('/signin');
  };

  return (
    <Stack direction="horizontal" alignX="end" alignY="center">
      <Text variant="body">Already have an account?</Text>
      <Button size="sm" onClick={handleClick}>
        Login
      </Button>
    </Stack>
  );
};

const Signup = () => {
  const { isMobile } = useWindowSize();
  const navigate = useNavigate();
  const handleClick = () => {
    navigationTracking(Action.SIGNUP);
    navigate('/signup');
  };

  const mobileAdjustments = {
    marginTop: '-6px', // needed to align with the Veeqo logo
  };

  return (
    <Stack direction="horizontal" alignX="end" alignY="center">
      {!isMobile && <Text variant="body">Don't have an account yet?</Text>}
      <Button size="sm" onClick={handleClick} style={isMobile ? mobileAdjustments : {}}>
        Signup
      </Button>
    </Stack>
  );
};

const Navbar = ({
  logo = true,
  login = true,
  signup = false,
  preventAnimations = false,
}: NavbarProps) => {
  const alignX = logo ? 'between' : 'end';

  return (
    <NavContainer direction="horizontal" alignX={alignX} alignY="center">
      {logo && (
        <motion.div
          key="logo"
          initial={{ opacity: preventAnimations ? 1 : 0 }}
          animate={{ opacity: preventAnimations ? 1 : 1 }}
        >
          <VeeqoLogo />
        </motion.div>
      )}
      {login && <Login />}
      {signup && <Signup />}
    </NavContainer>
  );
};

export default Navbar;
