import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppContext } from 'context/Context';

function useDefaultParamsCountry() {
  const {
    actions,
    state: {
      signupData: { country },
    },
  } = useAppContext();
  const [params] = useSearchParams();
  const queryCountry = params.get('country');

  useEffect(() => {
    if (queryCountry && queryCountry !== country) {
      actions.setSignupData({ country: queryCountry.toUpperCase() });
    }
    // TODO:: may be obvious but look into why we do not have the full list of deps here (actions.setSignupData is missing)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryCountry, country]);

  return queryCountry?.toUpperCase() || country;
}

export default useDefaultParamsCountry;
