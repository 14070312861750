import { AABeforeAuth } from './types';
import { getUTMParameters, reportSatelliteMissing } from '../utils';
import { PAGE_VIEW_EVENT_NAME } from '../constants';

/**
 * Called when the user visits the signup/login page for the first time.
 */
export const trackInitialPageView = () => {
  if (typeof _satellite === 'undefined') return reportSatelliteMissing();

  const utmParameters = getUTMParameters({ preferURLParams: true });
  const eventData: AABeforeAuth = {
    page: {
      url: window.location.href,
      domain: window.location.hostname,
      path: window.location.pathname,
      queryString: window.location.search,
      title: document.title,
      referrer: document.referrer,
      section: 'Sign Up App',
      errorPage: false,
      // Signup specific.
      loginCompleted: false,
      leadCreated: false,
      accountCreated: false,
    },
    user: {
      utmParameters,
    },
  };

  _satellite.track(PAGE_VIEW_EVENT_NAME, eventData);
  return true;
};
