/* eslint-disable */
type SourceMiddleware = {
  payload: any;
  next: (payload: any) => void;
  integrations: any;
};

/**
 * Extend Analytics.js to run a custom sanitiser before reaching Segment servers
 * @param payload event payload sent by Analytics.js
 * @param next next function to be called in the source middleware chain
 * @param integrations all the integrations that the payload is sent to
 */
export var sourceMiddleware = function ({ payload, next }: SourceMiddleware) {
  /**
   * Clean argument from a list of unwanted properties:
   */
  const sanitiser = (properties: any) => {
    const excludedValues = /(p.*w(o)?(r)?d)|secret|token|credential(s)?/i;

    if (properties === null) return properties; // e.g sanitiser(null) -> null
    if (typeof properties !== 'object') return properties; // e.g sanitiser(undefined) -> undefined, sanitiser('hi') -> 'hi'
    if (properties.length) return properties.map((prop: any) => sanitiser(prop));
    if (Array.isArray(properties)) return properties; // e.g sanitiser([]) -> []

    const newProperties: { [key: string]: any } = {}; // e.g sanitiser({}) -> {}
    Object.keys(properties)
      .filter((key: string) => !excludedValues.test(key))
      .forEach((key: string) => {
        newProperties[key] = sanitiser(properties[key]);
      });
    return newProperties;
  };

  const { type, traits, properties } = payload.obj;
  if (type === 'identify') {
    payload.obj.traits = sanitiser(traits);
  } else {
    payload.obj.properties = sanitiser(properties);
  }

  next(payload);
};
