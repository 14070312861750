import { AABeforeAuth } from './types';
import { getUTMParameters, reportSatelliteMissing } from '../utils';
import { PAGE_VIEW_EVENT_NAME } from '../constants';

/**
 * When the user completed first stage signup, LEAD_CREATED.
 */
export const trackUserCompletedFirstSignupStage = () => {
  if (typeof _satellite === 'undefined') {
    return reportSatelliteMissing({ shouldSentToSentry: true });
  }

  const utmParameters = getUTMParameters();
  const eventData: AABeforeAuth = {
    page: {
      url: window.location.href,
      domain: window.location.hostname,
      path: window.location.pathname,
      queryString: window.location.search,
      title: document.title,
      referrer: document.referrer,
      section: 'Sign Up App',
      errorPage: false,
      // Signup specific.
      loginCompleted: false,
      leadCreated: true, // Lead created once user progresses to second signup stage.
      accountCreated: false,
    },
    user: {
      utmParameters,
    },
  };

  _satellite.track(PAGE_VIEW_EVENT_NAME, eventData);
  return true;
};
