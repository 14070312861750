const formValidations = {
  email: {
    required: 'Email is required',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+$/i, // includes '+' emails
      message: 'Please enter a valid email address',
    },
  },
  phone: {
    required: 'Phone is required',
    minLength: {
      value: 7,
      message: 'Please enter a valid phone number',
    },
    maxLength: {
      value: 18,
      message: 'Please enter a valid phone number',
    },
    pattern: {
      value: /^\s*[+]{1}[1-9]+/i, // must start with '+' then a number between 1 and 9 (inclusive)
      message: 'Please start with country code e.g +1 or +44',
    },
  },
  login: {
    required: 'Required',
  },
  firstName: {
    required: 'First name is required',
  },
  lastName: {
    required: 'Last name is required',
  },
  companyName: {
    required: 'Company name is required',
  },
  password: {
    required: 'Password is required',
    minLength: {
      value: 8,
      message: 'Password must meet the requirements',
    },
    pattern: {
      value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@!%*?&£$#%^&*]).+$/,
      message:
        'Password must meet the requirements',
    },
  },
  country: {
    required: 'Country is required',
  },
  addressLine1: {
    required: 'Address line is required',
  },
  city: {
    required: 'City is required',
  },
  region: {
    required: 'Region is required',
  },
  postCode: {
    required: 'Postcode is required',
  },
  monthlyOrderVolume: {
    required: 'Orders per month is required',
  },
};

export default formValidations;
