import * as Sentry from '@sentry/react';
import { LoginResponse } from 'utils/api';
import { UtmParameters } from './types';

/**
 * Gets a specified cookie off the window object, returning undefined if it doesn't exist otehrwise
 * returning parsed JS object.
 */
export const getCookie = (cookieName: string): Object | undefined => {
  const cookies = `; ${document.cookie}`;
  const parts = cookies.split(`; ${cookieName}=`);

  if (parts.length === 2) {
    try {
      return JSON.parse(parts[1].split(';')[0]);
    } catch (error) {
      Sentry.captureException(error, (scope) => {
        scope.setTransactionName(`Failed to parse cookie: ${parts}`);
        return scope;
      });
      return undefined;
    }
  } else {
    Sentry.captureMessage(`Failed to find cookie: ${cookieName}`);
  }

  return undefined;
};

/**
 * Adds UTM params from URL to passed UTM params.
 *
 * Prefers to use the ones from the URL, but will use passed value as backup.
 */
export const addutmURLParams = (fromCookie: UtmParameters): UtmParameters => {
  const urlParams = new URLSearchParams(window.location.search);

  const utmParams = {
    campaign: urlParams.get('utm_campaign') || fromCookie.campaign,
    source: urlParams.get('utm_source') || fromCookie.source,
    content: urlParams.get('utm_content') || fromCookie.content,
    id: urlParams.get('utm_id') || fromCookie.id,
    medium: urlParams.get('utm_medium') || fromCookie.medium,
    referral: urlParams.get('utm_referral') || fromCookie.referral,
    term: urlParams.get('utm_term') || fromCookie.term,
  } as UtmParameters;

  return utmParams;
};

export const getUTMParameters = ({ preferURLParams = false } = {}): UtmParameters => {
  const cookie = (getCookie('campaign_first') ?? {}) as any;

  const utmParameters: UtmParameters = {
    campaign: cookie.utm_campaign || '',
    source: cookie.utm_source || '',
    content: cookie.utm_content || '',
    id: cookie.utm_id || '',
    medium: cookie.utm_medium || '',
    referral: cookie.utm_referral || '',
    term: cookie.utm_term || '',
  };

  // If marketing want to URL as source of truth.
  if (preferURLParams) return addutmURLParams(utmParameters);

  return utmParameters;
};

export const reportSatelliteMissing = ({ shouldSentToSentry = false } = {}) => {
  if (shouldSentToSentry) Sentry.captureMessage('AA - _satellite missing');
  console.warn('AA - _satellite missing'); // eslint-disable-line no-console
  return false;
};

export const reportUserDataMissing = ({
  user_id,
  company_id,
  company_name,
}: Pick<LoginResponse, 'company_id' | 'company_name' | 'user_id'>) => {
  const missingFields = [];
  if (!user_id) missingFields.push('user_id');
  if (!company_id) missingFields.push('company_id');
  if (!company_name) missingFields.push('company_name');

  if (missingFields.length === 0) return;

  let errorMessage = 'AA: ';
  if (missingFields.length === 3) {
    errorMessage += 'user_id, company_id and company_name not returned';
  } else {
    errorMessage += `${missingFields.join(' and ')} not returned`;
  }

  Sentry.captureMessage(errorMessage);
};
