import { ComponentProps } from 'react';

export const EmailIcon = (props: ComponentProps<'svg'>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.8 5H19.2C20.19 5 21 5.7875 21 6.75V17.25C21 18.2125 20.19 19 19.2 19H4.8C3.81 19 3 18.2125 3 17.25L3.009 6.75C3.009 5.7875 3.81 5 4.8 5ZM11.9998 12.875L19.1998 8.49999V6.74999L11.9998 11.125L4.7998 6.74999V8.49999L11.9998 12.875Z"
      fill="currentColor"
    />
  </svg>
);
